

const doctorDiagnosisData = [
  {
    title: 'Diagnosis and Testing',
    titleColor: '#01AEF1',
    questionList:
      [
        'What exactly is my Hodgkin lymphoma diagnosis?',
        'What stage is my Hodgkin lymphoma?',
      ]
  }
]

const doctorTreatmentData = [
  {
    title: 'Treatment',
    titleColor: '#7E3F97',
    questionList:
      [
        `What are all of the treatment options available to me?`,
        `What are the goals of treatment?`,
        `What is the recommended treatment for my Hodgkin lymphoma? What are the benefits and risks?`,
        `What are the associated side effects and long-term effects of the recommended treatment?`,
        `How long will I need to stay on treatment?`,
        `How much time do I have to make a decision about my treatment plan?`,
        `How can I preserve my fertility before beginning therapy? How will treatment affect my sexuality?`,
        `Will I be in the hospital or will I be an outpatient during treatment? Will I be able to work or attend school during my treatment?`,
        `What kind of testing will be done to monitor the Hodgkin lymphoma and treatment? How often will testing be needed?`,
        `How will I know if the treatment is effective? What options are available if the treatment is not effective?`
      ]
  }
]

const doctorSocialAndFinancialData = [
  {
    title: 'Social/Financial Concerns',
    titleColor: '#7E3F97',
    questionList:
      [
        `Will I lose my hair?`,
        `What kind of financial and social support services are available to me and my family?`,
        `Who is the best person to speak to about bills and insurance coverage?`,
        `If I do not have insurance coverage, how can the healthcare team help me get treatment? Is there someone I can speak to for assistance?`,
      ]
  }
]

const doctorFollowUpData = [
  {
    title: 'Follow-Up Care',
    titleColor: '#01AEF1',
    questionList:
      [
        `Will the healthcare team continue to check on me after the treatment is over? If so, for what period of time?`,
        `I would like to continue some type of lifelong follow-up care in order to be monitored for long-term effects of Hodgkin lymphoma treatment. Can I follow up with this team?`,
      ]
  }
]

const DoctorGuideListData = {
  doctorDiagnosisData,
  doctorTreatmentData,
  doctorSocialAndFinancialData,
  doctorFollowUpData,
}

export default DoctorGuideListData