import React from 'react'
import { css } from '@emotion/core'

import { mq } from '../../utils/media-queries'

const DoctorGuideList = (props) => {
  const { DoctorGuideListData } = props;
  const { title, titleColor, questionList } = DoctorGuideListData[0];
  return (
    <div
      css={css`
        margin: 40px 0;
        ${[mq[2]]} {
          margin-bottom: 0;
        }
        h3 {
          font-size: 24px;
          font-weight: bold;
          line-height: normal;
        }
        li {
          font-size: 18px;
          ${[mq[2]]} {
            font-size: 14px;
          }
        }
      `}
    >
      <div
        css={css`
          height: 12px;
          background-image: linear-gradient(to bottom, #ae58cf, #7e3f97);
        `}
      >

      </div>
      <div
        css={css`
          background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 37%, #dddddd);
          margin: 32px 0 0 0;
          padding: 16px 15px;
        `}
      >
        <h3
          style={{ color: titleColor }}
          css={css`
            padding-bottom: 16px;
          `}
        >
          {title}
        </h3>
        <ol
          css={css`
            padding: 0 0 0px 18px;
          `}
        >
          {
            questionList.map((question, i) => {
              return (
                <li
                  css={css`
                    padding-bottom: 12px;
                  `}
                >
                  {question}
                </li>
              )
            })
          }
        </ol>
      </div>
    </div>
  )
}

export default DoctorGuideList